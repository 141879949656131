import { RANDBETWEEN } from "@formulajs/formulajs";
import { useControls } from "leva";
import { useEffect, useRef } from "react";
import { degToRad } from "three/src/math/MathUtils";
import { getv } from "../../utils/utils";
import CrystalTrail from "./CrystalTrail";
import DirtTrail from "./DirtTrail";

export const CarTrail = () => {
  const ct1 = useRef({
    particles: 5,
    size_start: 0.7,
    size_reduction: 0.1,
    velocity_x: 10,
    color_hue: 36,
    color_sat: 0.39,
    color_trans: 0.1,
  });
  const ct2 = useRef({
    particles: 7,
    size_start: 0.4,
    size_reduction: 0.2,
    velocity_x: 3,
    color_hue: 36,
    color_sat: 0.39,
    color_trans: 0.1,
  });

  useEffect(() => {
    const fn = () => {
      ct1.current.particles = RANDBETWEEN(1, 10);
      ct1.current.size_start = RANDBETWEEN(0.4, 0.8);
      ct1.current.size_reduction = RANDBETWEEN(-0.3, 0.2);
      ct1.current.velocity_x = RANDBETWEEN(5, 20);
    };
    let timer = setInterval(fn, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const conf1 = {
    particles: getv(ct1.current, "particles"),
    size_start: getv(ct1.current, "size_start"),
    size_reduction: getv(ct1.current, "size_reduction"),
    color_hue: getv(ct1.current, "color_hue"),
    color_sat: getv(ct1.current, "color_sat"),
    color_trans: getv(ct1.current, "color_trans"),
    velocity_x: getv(ct1.current, "velocity_x"),
  };

  const conf2 = {
    particles: getv(ct2.current, "particles"),
    size_start: getv(ct2.current, "size_start"),
    size_reduction: getv(ct2.current, "size_reduction"),
    color_hue: getv(ct2.current, "color_hue"),
    color_sat: getv(ct2.current, "color_sat"),
    color_trans: getv(ct2.current, "color_trans"),
    velocity_x: getv(ct2.current, "velocity_x"),
  };

  const confpos = {
    position_x: 0,
    position_y: 0.3,
    position_z: 0,
    front_move_x: -1.3,
    back_move_x: 0,
    left_move_z: 0.4,
    right_move_z: -0.4,
  };

  const Comp = DirtTrail;
  return (
    <>
      <group
        rotation-z={degToRad(-180)}
        position={[confpos.position_x, confpos.position_y, confpos.position_z]}
      >
        <group
          position-x={confpos.front_move_x}
          position-z={confpos.left_move_z}
        >
          <Comp override_config={conf2} />
        </group>

        <group
          position-x={confpos.front_move_x}
          position-z={confpos.right_move_z}
        >
          <Comp override_config={conf2} />
        </group>

        <group
          position-x={confpos.back_move_x}
          position-z={confpos.left_move_z}
        >
          <Comp override_config={conf1} />
        </group>

        <group
          position-x={confpos.back_move_x}
          position-z={confpos.right_move_z}
        >
          <Comp override_config={conf1} />
        </group>
      </group>
    </>
  );
};
