import { Environment } from "@react-three/drei";
import { degToRad } from "three/src/math/MathUtils";
import { base_remote, environment_file } from "./ModelsStore";

function StarsEnvironment({ ...props }) {
  // return null;
  return (
    <Environment
      rotation-z={degToRad(-90)}
      background
      files={environment_file}
    />
  );
}

export default StarsEnvironment;
